import { onboardingInstance } from '../../shared/axios';
import { PERSON_TYPE, endpoints } from '../../shared/constants';

type OnboardingDataCommon = {
  phoneNumber: string;
  activity: string;
  clabe: string;
  bank: string;
  street: string;
  externalNumber: string;
  internalNumber: string;
  provinces: string;
  cities: string;
  suburbs: string;
  zipCode: string;
  personType: string;
  businessName: string;
  federalEntityOfBirth: string;
  name: string;
  lastname: string;
  secondlastname: string;
  birthday: string;
  countryOfBirth: string;
  latitude?: number;
  longitude?: number;
};

export type OnboardingDataPPFF = OnboardingDataCommon;

export type OnboardingDataPPMM = {
  rfc: string;
  formationDate: string;
  countryOfRegister: string;
  ownerCountryOfBirth: string;
} & OnboardingDataCommon;

export type OnboardingDataPFAE = {
  pfaeRFC: string;
} & OnboardingDataCommon;

export type OnboardingData =
  | OnboardingDataPPFF
  | OnboardingDataPPMM
  | OnboardingDataPFAE;

const getEndpointByPersonType = (personType: string, email: string) => {
  if (personType === PERSON_TYPE.PPMM) {
    return endpoints.ONBOARDING_FORM_PPMM;
  }
  return endpoints.ONBOARDING_FORM.replace('{email}', email);
};

const postOnboarding = async (
  onboardingData: OnboardingData,
  email: string,
  token: string,
  latitude?: string,
  longitude?: string
) => {
  const endpoint = getEndpointByPersonType(onboardingData.personType, email);

  let data: any = {
    federal_entity_of_birth: onboardingData.federalEntityOfBirth,
    first_name: onboardingData.name,
    last_name: onboardingData.lastname,
    second_last_name: onboardingData.secondlastname,
    birthday: onboardingData.birthday,
    country_of_birth: onboardingData.countryOfBirth,
    business_name: onboardingData.businessName,
    phone_number: onboardingData.phoneNumber,
    activity: onboardingData.activity,
    clabe: onboardingData.clabe.replaceAll(' ', ''),
    bank: onboardingData.bank,
    street: onboardingData.street,
    external_number: onboardingData.externalNumber,
    internal_number: onboardingData.internalNumber,
    province: onboardingData.provinces,
    city: onboardingData.cities,
    suburb: onboardingData.suburbs,
    zip_code: onboardingData.zipCode,
    user_type: onboardingData.personType,
    latitude,
    longitude
  };

  if (onboardingData.personType === PERSON_TYPE.PPMM) {
    const obData = onboardingData as OnboardingDataPPMM;
    data = {
      DocumentID: obData.rfc.replaceAll(' ', ''),
      FormationDate: obData.formationDate,
      RegistrationCountry: obData.countryOfRegister,
      BusinessName: obData.businessName,
      ActivityCode: obData.activity,
      Street: obData.street,
      ExternalNumber: obData.externalNumber,
      InternalNumber: obData.internalNumber,
      Province: obData.provinces,
      City: obData.cities,
      Suburb: obData.suburbs,
      ZipCode: obData.zipCode,
      BankAccount: obData.clabe.replaceAll(' ', ''),
      BankName: obData.bank,
      OwnerFirstName: obData.name,
      OwnerLastName: obData.lastname,
      OwnerBirthday: obData.birthday,
      OwnerCountryOfBirth: obData.ownerCountryOfBirth,
      OwnerFederalEntity: onboardingData.federalEntityOfBirth,
      PhoneNumber: onboardingData.phoneNumber
    };
  }

  if (onboardingData.personType === PERSON_TYPE.PROFESSIONAL) {
    const obData = onboardingData as OnboardingDataPFAE;
    data = {
      rfc: obData.pfaeRFC.replaceAll(' ', ''),
      first_name: obData.name,
      last_name: obData.lastname,
      second_last_name: obData.secondlastname,
      birthday: obData.birthday,
      country_of_birth: obData.countryOfBirth,
      business_name: obData.businessName,
      activity: obData.activity,
      phone_number: obData.phoneNumber,
      clabe: obData.clabe.replaceAll(' ', ''),
      bank: obData.bank,
      street: obData.street,
      external_number: obData.externalNumber,
      internal_number: obData.internalNumber,
      province: obData.provinces,
      city: obData.cities,
      suburb: obData.suburbs,
      zip_code: obData.zipCode,
      user_type: obData.personType,
      latitude,
      longitude
    };
  }

  const response = await onboardingInstance.post(endpoint, data, {
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'application/json'
    }
  });
  return response.data;
};

export { postOnboarding };
