import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { UserContextDispatch } from '../../contexts/UserDataProvider';
import { Alert } from '@uala/abra';
import { useSnackbar } from '@uala/ui';
import { getErrorMessageByType } from './utils';
import data from './data/createAccountForm.json';
import { CustomValidationProps } from '../../components/GenericForm/types';
import GenericForm from '../../components/GenericForm';
import useDesktopSize, { useGetDevice } from '../../hooks/useDesktopSize';
import { getRegistrationStatus, postSignupUser } from '../../services/signup';
import { checkResponseStatus } from '../../services/utils';
import {
  createAmplitudeEvent,
  setUserProp
} from '../../contexts/AmplitudeProvider';
import { ERROR_CODES } from './errorsCodes';
import { PERSON_TYPE, REGISTRATION_STATUS } from '../../shared/constants';

const CreateAccount: React.FC = () => {
  const authDispatch = useContext(UserContextDispatch);
  const [loading, setLoading] = useState(false);
  const [captchaError, setCaptchaerror] = useState(false);
  const navigate = useNavigate();
  const addSnackbar = useSnackbar();
  const isDesktop = useDesktopSize({ breakpoint: 1200 });
  const device = useGetDevice();
  const personType =
    window.sessionStorage.getItem('personType') || PERSON_TYPE.PPFF;

  const onSubmit = async (data: any) => {
    authDispatch((user) => ({
      ...user,
      accountEmail: data.email
    }));

    setLoading(true);
    grecaptcha.enterprise.ready(async () => {
      try {
        createAmplitudeEvent('registro_cobros_ingresa_contraseña', {
          device,
          user_type: personType
        });

        const token = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_RECAPTCHA_KEY!,
          { action: 'create_account' }
        );

        const response: any = await postSignupUser({
          ...data,
          token,
          personType
        });
        window.sessionStorage.setItem('formValues', '{}');
        checkResponseStatus(response);
        navigate('/validate-email');
      } catch (error: any) {
        const errorMessage = getErrorMessageByType(error.response?.data);
        if (errorMessage === ERROR_CODES['409']) {
          createAmplitudeEvent('registro_cobros_recibe_mail_en_uso', {
            device
          });
        }

        if (error.response.status === 401) {
          setCaptchaerror(true);
        } else {
          addSnackbar(errorMessage);
        }

        setLoading(false);
      }
    });
  };

  const partialSubmit = async (data: { email: string }) => {
    setUserProp('email', data.email);
    createAmplitudeEvent('registro_cobros_ingresa_mail', {
      device,
      user_type: personType
    });

    try {
      setLoading(true);
      const { RegistrationStatus: registrationStatus } =
        await getRegistrationStatus(data.email);
      switch (registrationStatus) {
        case REGISTRATION_STATUS.INCOMPLETE:
          authDispatch((user) => ({
            ...user,
            accountEmail: data.email
          }));
          sessionStorage.removeItem('formValues');
          navigate('/return-onboarding');
          return false;
        case REGISTRATION_STATUS.FINISHED:
          sessionStorage.removeItem('formValues');
          navigate('/finished-onboarding');
          return false;
      }
      setLoading(false);
      return true;
    } catch (error) {
      addSnackbar('Error al validar el emal');
      setLoading(false);
      return false;
    }
  };

  const handleCustomValidations = ({
    input,
    methods
  }: CustomValidationProps) => {
    switch (input.name) {
      case 'password':
        input.validations = {
          ...input.validations,
          validate: (value: string) => {
            const rpName = 'repeatPassword';
            let rp = methods!.getValues(rpName);
            if (rp !== undefined && rp !== '') {
              if (value === rp) {
                methods!.clearErrors(rpName);
              } else {
                methods!.trigger(rpName);
              }
            }
          }
        };
        break;

      case 'repeatPassword':
        input.validations = {
          ...input.validations,
          validate: (value: string) => {
            let p = methods!.getValues('password');
            return value === p || 'Las contraseñas no coinciden';
          }
        };
        break;

      default:
        break;
    }
  };

  return (
    <GenericForm
      form={data.steps}
      onSubmit={onSubmit}
      partialSubmit={partialSubmit}
      firstBackButton={!isDesktop ? '/' : undefined}
      showBackButton
      loading={loading}
      showLogo={isDesktop}
      illustrationPosition={isDesktop ? 'side' : undefined}
      handleCustomValidations={handleCustomValidations}
    >
      {captchaError && (
        <Alert
          description="reCAPTCHA detectó un comportamiento inusual."
          variant="error"
          css={{ marginBottom: '16px' }}
        />
      )}
    </GenericForm>
  );
};

export default CreateAccount;
